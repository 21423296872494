import { TemplateData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';

interface ModalSendTemplateProps {
  templateId: string;
  onClose: (event?: SyntheticEvent<HTMLButtonElement>) => void;
}
const ModalSendTemplate: FC<ModalSendTemplateProps> = function ({
  templateId,
  onClose,
}) {
  const [email, setEmail] = useState<string>('');

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmail(value);
  };

  const handleSendEmailOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (email) {
      await toast.promise(TemplateData.sendTest({ email, id: templateId }), {
        error: "Une erreur c'est produite lors du traitement",
        pending: "Le traitement est en cours d'exécution",
        success: 'Le modèle a bien été envoyé par e-mail',
      });
      onClose();
    } else {
      toast.error("L'adresse e-mail doit être renseignée");
    }
  };

  return (
    <div>
      <p>{`Veuillez saisir l'adresse e-mail du destinataire ?`}</p>
      <div className="mt-3 flex flex-col items-center justify-center space-y-3">
        <input
          className="h-[42px] w-full rounded border border-primary-300 bg-neutral-50 px-4"
          onChange={handleOnChange}
          type="text"
          value={email}
        />
        <Button
          color="neutral"
          onClick={handleSendEmailOnClick}
          variant="solid"
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default ModalSendTemplate;
